a.skip-to-content {
    position: absolute;
    top: -40px;
    left: 0;
    -webkit-transition: top 1s ease-out;
    transition: top 1s ease-out;

    &.skip-to-content:focus {
        background-color: #ed1b2e;
        color: #fff;
        left: 4px;
        padding: 1rem;
        text-decoration: underline;
        top: 4px;
        -webkit-transition: top 0.1s ease-in;
        transition: top 0.1s ease-in;
        z-index: 10;
    }
}

.main {
    margin-top: 78.75px;
}